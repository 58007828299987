<template>
  <div class="usContact">
    <div class="title langEnB ar"><span>{{ langContactUs.contactUs }}</span></div>
    <div class="content ar">
      juhai online
      <!-- <div>
        <div>{{ langContactUs.contactBT }}</div>
        <span>Business@Amusement.ai</span>
      </div>
      <div>
        <div>{{ langContactUs.inquiries }}</div>
        <span>Cooperation@Amusement.ai</span>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{

    }
  },
  computed: {
    ...Vuex.mapGetters([
      "langContactUs",
    ]),
  }
}
</script>
<style lang="stylus" scoped>
.usContact{
  width: 100%;
  max-width: 12.3rem;
  margin: 0 auto;
}
.title{
  position: relative;
  font-size: $Ftm1;
  text-align: center;
  margin: .4rem auto .38rem;
  box-sizing: border-box;
  width: 100%;
  max-width: 12.3rem;
  padding: 0 .2rem;
  & span{
    background-color: $Cf;
    padding: 0 .23rem;
    z-index: 10;
    position: relative;
  }
  &::after{
    content: "";
    position: absolute;
    display:inline-block;
    height: 1px;
    background-color: $CFtGy5;
    top: 50%;
    left: .2rem;
    right: .2rem;
    z-index: 0;
  }
}
.content{
  font-size: $Fts2;
  margin: .8rem auto 1.2rem;
  padding: 0 1rem;
  text-align: center;
  // flex(row, wrap);
  & p{
    line-height: .22rem;  
    margin: .2rem 0;
  }
  & div{
    text-align: center;
    font-size: $Fts2;
    margin: .1rem 0;
    & div{
      font-family: 'mainEnB';
    }
    & span{
      color: $CLGy1;
    }
  }
}
@media screen and (max-width: 980px){
  .usContact{
    margin-bottom: .4rem;
  }
  .title{
    margin: .8rem auto .15rem;  
    &::after{
      content: "";
      right: .16rem;
      left: .16rem;
    }
  }
  .content {
    display: block;
    font-size: $Fts4;
    text-align: center;
    margin: .1rem .16rem 0;
    padding: 0;
    flex(column, wrap,center,center);
    & div{
      width: 100%;  
    }
  }
}

</style>