<template>
  <div class="gameList">
    <div class="title langEnB ar" v-if="datas.title"><span>{{ langCommon.games }}</span></div>
    <ul class="flex"
      @mouseleave="mouseLStatus = false">
      <li
        v-for="(item,index) in datas.games"
        :key="'homeGames_'+index"
        @mouseenter="mouseenterFn(index)"
        @mouseleave="mouseleaveFn(index)"
        @click="$router.push({path:'game',query:{id:item.games}})"
        :class="[{'enter':mouseStatus===index},{'leave':mouseLStatus===index}]">
        <img :src="item.url" alt="">
        <div class="intro">
          <span>{{item.intro}}</span>
          <span></span>
          <span class="langEnI">{{ item.text }}</span>
        </div>
      </li>
    </ul>
    <a 
      v-if="datas.more" 
      :class="['more',{'enter':moreStatus===1},{'leave':moreStatus===2}]"
      @mouseenter="moreStatus=1;"
      @mouseleave="moreStatus=2"
      @click="$router.push({path:'games'})">
      {{ langCommon.more }}
    </a>
  </div>
</template>
<script>
export default {
  data(){
    return{
      mouseStatus: false,
      moreStatus: false,
    }
  },
  props: [ "datas" ],
  computed: {
    ...Vuex.mapGetters([
      "langCommon",
    ]),
  },
  methods: {
    mouseenterFn(index){
      console.log(index)
      this.mouseStatus = index;
    },
    mouseleaveFn(index){
      this.mouseStatus = false;
      this.mouseLStatus = index;
    }
  }
}
</script>
<style lang="stylus" scoped>
.gameList{
  width: 100%;
  max-width: 12.3rem;
  margin: .3rem auto .2rem;
  
}
.title{
  position: relative;
  font-size: $Ftm1;
  text-align: center;
  box-sizing: border-box;
  margin: 0 auto .3rem;
  padding: 0 .2rem;
  & span{
    background-color: $Cf;
    padding: 0 .23rem;
    z-index: 10;
    position: relative;
  }
  &::after{
    content: "";
    position: absolute;
    display:inline-block;
    height: 1px;
    background-color: $CFtGy5;
    top: 50%;
    left: .2rem;
    right: .2rem;
    z-index: 0;
  }
}
ul{
  margin: 0 auto;
  padding: 0 .2rem;
}
li{
  position: relative;
  width: 3.89rem;
  cursor: pointer;
  background-color: $C0;
  overflow: hidden;
  &:nth-child(2){
    margin:0 .16rem;  
  }
  & img{
    width: 100%;
    opacity: .5;
  }
  & .intro{
    font-size: $Fts2;
    color: $Cf;
    position: absolute;
    bottom: 8%;
    left: 10%;
    width: 80%;
    & span{
      display: block;
      &:nth-child(1){
        font-size: $Fts5;
        font-weight: inherit;
        word-break: break-word;
      }  
      &:nth-child(2){
        width: 20%;
        border-bottom: .015rem solid $CLGy1;
        margin: .05rem 0 .14rem;
      }
    }  
  }
  &.enter{
    & img{    
      animation: gameScaleEnterKey 0.2s ease-in-out;
      -webkit-animation: gameScaleEnterKey 0.2s ease-in-out;
      animation-fill-mode: forwards;
      animation-delay: 0.1s;
      -webkit-animation-delay: 0.1s;
    }
    & .intro{
      animation: introToBtmKey 0.4s ease-in-out;
      -webkit-animation: introToBtmKey 0.4s ease-in-out;
      animation-fill-mode: forwards;
    }
  }
  &.leave{
    & img{    
      animation: gameScaleLeaveKey 0.2s ease-in-out;
      -webkit-animation: gameScaleLeaveKey 0.2s ease-in-out;
      animation-fill-mode: forwards;
    }
    & .intro{
      animation: introToTopKey 0.4s ease-in-out;
      -webkit-animation: introToTopKey 0.4s ease-in-out;
      animation-fill-mode: forwards;
    }
  }
}
.more{
  font-size: $Fts3;
  display: block;
  cursor: pointer;
  width: 20%;
  text-align: center;
  color: $CLGy1;
  border: .01rem solid $CLGy1;
  margin: .3rem auto .54rem;
  height: .4rem;
  line-height: .4rem;
  &.enter{
    animation: moreEnterKey 0.2s ease-in-out;
    -webkit-animation: moreEnterKey 0.2s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 0.1s;
    -webkit-animation-delay: 0.1s;
  }
  &.leave{
    animation: moreLeaveKey 0.2s ease-in-out;
    -webkit-animation: moreLeaveKey 0.2s ease-in-out;
    animation-fill-mode: forwards;
  }
}
@media screen and (max-width: 980px){
  .gameList{
    & ul {
      flex(row,wrap,space-between,center);
      padding: 0 .16rem;
    }
    & li {
      width: 48.4%;
      margin: 0 0 .16rem;
    }
  }
  .title{
    &::after{
      content: "";
      right: .16rem;
      left: .16rem;
    }
  }
  .more{
    width: 38%;  
  }
}
@keyframes gameScaleEnterKey {
  from {
    opacity: .5;
    tranform(scale(1));
  }
  to {
    opacity: 1;
    tranform(scale(1.1));

  }
}
@keyframes introToBtmKey {
  from {
    bottom: 8%;
  }
  to {
    bottom: -50%;
  }
}
@keyframes gameScaleLeaveKey {
  from {
    opacity: 1;
    tranform(scale(1.1));
  }
  to {
    opacity: .5;
    tranform(scale(1));
  }
}
@keyframes introToTopKey {
  from {
    bottom: -50%;
  }
  to {
    bottom: 8%;
  }
}
@keyframes moreEnterKey {
  from {
    color: $CLGy1;
    background-color: $Cf;
  }
  to {
    color: $Cf;
    background-color: $CLGy1;
  }
}
@keyframes moreLeaveKey {
  from {
    color: $Cf;
    background-color: $CLGy1;
  }
  to {
    color: $CLGy1;
    background-color: $Cf;
  }
}
</style>