<template>
  <div class="home">
    <i-home-banner :datas="bannerImg"></i-home-banner>
    <i-game-list :datas="{games:listGames,title:true,more:true}"></i-game-list>
    <i-us-about :datas="{bgImg:true}"></i-us-about>
    <i-us-contact></i-us-contact>
    <i-main-share></i-main-share>
  </div>
</template>
<script>
import IHomeBanner from "@/components/Banner/home.vue"
import IGameList from "@/components/Game/list.vue"
import IUsAbout from "@/components/us/about.vue"
import IUsContact from "@/components/us/contact.vue"
import IMainShare from "@/components/share/main.vue"
export default {
  data(){
    return {

    }
  },
  components: {
    IHomeBanner,
    IGameList,
    IUsAbout,
    IUsContact,
    IMainShare
  },
  computed: {
    ...Vuex.mapGetters([
      "bannerImg",
      "headerImg",
    ]),
    listGames(){
      let games = [];
      console.log(123213)
      console.log(this.headerImg)
      for(let i = 0; i<this.headerImg.length;i++){
        if(this.headerImg[i]["showList"]){
          games.push(this.headerImg[i])
        }
      }
      return games;
    }
  },

}
</script>